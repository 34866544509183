import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  series,
  seriesForm,
  episodes,
  episodesForm,
} from "./Reducers";

export default combineReducers({
  episodes,
  episodesForm,
  series,
  seriesForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});
