import { createCrudActions } from '../utils/crudGenerator';

const formatEpisodes = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchEpisodes,
    create: createEpisode,
    update: updateEpisode,
    delete: deleteEpisodes
} = createCrudActions('EPISODE', "Episodio", formatEpisodes);