/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USER_FORM = "OPEN_USER_FORM";
export const CLOSE_USER_FORM = "CLOSE_USER_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* EPISODE */
export const SET_EPISODE_LIST = "SET_EPISODE_LIST";
export const ADD_NEW_EPISODE = "ADD_NEW_EPISODE";
export const UPDATE_EPISODE = "UPDATE_EPISODE";
export const DELETE_EPISODE = "DELETE_EPISODE";
export const OPEN_EPISODE_FORM = "OPEN_EPISODE_FORM";
export const CLOSE_EPISODE_FORM = "CLOSE_EPISODE_FORM";
export const EDIT_SELECTED_EPISODE = "EDIT_SELECTED_EPISODE";
export const EPISODE_FORM_TOOGLE_LOADING = "EPISODE_FORM_TOOGLE_LOADING";
/* <---- EPISODE ----> */

/* SERIE */
export const SET_SERIE_LIST = "SET_SERIE_LIST";
export const ADD_NEW_SERIE = "ADD_NEW_SERIE";
export const UPDATE_SERIE = "UPDATE_SERIE";
export const DELETE_SERIE = "DELETE_SERIE";
export const OPEN_SERIE_FORM = "OPEN_SERIE_FORM";
export const CLOSE_SERIE_FORM = "CLOSE_SERIE_FORM";
export const EDIT_SELECTED_SERIE = "EDIT_SELECTED_SERIE";
export const SERIE_FORM_TOOGLE_LOADING = "SERIE_FORM_TOOGLE_LOADING";
/* <---- SERIE ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */
